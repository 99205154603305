import store from 'store/modules/ondemand'

const beforeEnter = (to, from, next) => {
  if (!store.state.stash.params) {
    return next({ name: 'ondemand-coach' })
  } else {
    next()
  }
}

const form = {
  name: 'ondemand-coach',
  path: '/on-demand/coach',
  components: {
    default: () => import(/* webbpackChunkName: "ondemand-coach" */ 'pages/ondemand/coach/index.vue')
  },
  meta: {
    label: 'Coach: Search'
  }
}

const outboundResults = {
  name: 'ondemand-coach-outbound',
  path: '/on-demand/coach/outbound',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-coach" */ 'pages/ondemand/coach/results.vue')
  },
  props: {
    default: {
      isOutbound: true,
      isReturn: false
    }
  },
  meta: {
    label: 'Coach: Outbound Results',
    // TODO: Can probably remove... to check if required in befoerRouteEnter for anything...
    isReturn: false,
    isOutbound: true
  },
  beforeEnter
}

const returnResults = {
  name: 'ondemand-coach-return',
  path: '/on-demand/coach/return',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-coach" */ 'pages/ondemand/coach/results.vue')
  },
  props: {
    default: {
      isOutbound: false,
      isReturn: true
    }
  },
  meta: {
    label: 'Coach: Return Results',
    // TODO: Can probably remove... to check if required in befoerRouteEnter for anything...
    isReturn: true,
    isOutbound: false
  },
  beforeEnter
}

const holdLuggage = {
  name: 'ondemand-coach-luggage',
  path: '/on-demand/coach/luggage',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-coach" */ 'pages/ondemand/coach/hold-luggage.vue')
  },
  meta: {
    label: 'Coach: Hold Luggage'
  },
  beforeEnter
}

const summary = {
  name: 'ondemand-coach-summary',
  path: '/on-demand/coach/summary',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-coach" */ 'pages/ondemand/coach/summary.vue')
  },
  meta: {
    label: 'Coach: Summary'
  },
  beforeEnter
}

const passengerInfo = {
  name: 'ondemand-coach-passenger-info',
  path: '/on-demand/coach/passenger-info',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-coach" */ 'pages/ondemand/coach/passenger-info.vue')
  },
  meta: {
    label: 'Coach: Passenger Information'
  },
  beforeEnter
}

// TODO: Remove once flow has been done for multi-journey
const tempHolding = {
  name: 'ondemand-coach-holding',
  path: '/on-demand/coach/holding',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-coach" */ 'pages/ondemand/coach/temp-holding.vue')
  },
  meta: {
    label: 'Coach: Holding'
  },
  beforeEnter
}

export default { form,
  holdLuggage,
  outboundResults,
  returnResults,
  summary,
  passengerInfo,
  tempHolding
}
