/**
 * Colours found in the color tag are set in src/styles/variabls/colours.styl
 */

import { mapGetters } from 'vuex'
import { hasPermission } from 'utils/authentication'

export default {
  data () {
    return {
      travelContents: {
        // Travel types
        bikehire: {
          icon: 'fas fa-bicycle',
          mapIcon: 'bike',
          color: 'gohi-bike',
          hex: '#198642',
          altColor: 'white',
          background: 'backgrounds/bike.jpg',
          permission: 'search.bikehire',
          routes: {
            search: 'ondemand-bikehire',
            results: 'ondemand-bikehire-results'
          }
        },
        bikeshare: {
          icon: 'fas fa-bicycle',
          mapIcon: 'bike',
          color: 'gohi-bus',
          hex: '#198642',
          altColor: 'white',
          background: 'backgrounds/bike.jpg',
          permission: 'search.bikeshare',
          routes: {
            search: 'ondemand-bikeshare',
            results: 'ondemand-bikeshare'
          }
        },
        bus: {
          text: 'Bus',
          icon: 'fas fa-bus',
          hex: '#4361EE',
          color: 'bus',
          background: 'backgrounds/bus.jpg',
          permission: 'search.bus',
          routes: {
            search: 'ondemand-bus',
            results: 'ondemand-bus-routes'
          },
          provider: 'mobilleo'
        },
        carclub: {
          icon: 'fas fa-cars',
          hex: '#198642',
          color: 'carclub',
          background: 'backgrounds/cc.jpg',
          permission: 'search.carclub',
          routes: {
            search: 'ondemand-carclub',
            results: 'ondemand-carclub-results'
          },
          provider: 'enterprise-carclub'
        },
        coach: {
          pref: 'coach',
          color: 'gohi-bus',
          hex: '#4361EE',
          background: 'backgrounds/bus.jpg',
          routes: {
            search: 'ondemand-coach',
            results: 'ondemand-coach-results'
          }
        },
        drt: {
          icon: 'fas fa-shuttle-van',
          color: 'gohi-bus',
          hex: '#4361EE',
          background: 'backgrounds/bus.jpg',
          routes: {
            search: 'ondemand-drt',
            results: 'ondemand-drt-summary'
          }
        },
        'dial-m': {
          asset: 'icons/dial-m-m.svg',
          icon: 'asset',
          color: 'gohi-bus',
          hex: '#EB79FC',
          background: 'backgrounds/bus.jpg',
          permission: 'search.drt',
          routes: {
            search: 'ondemand-drt',
            results: 'ondemand-drt-summary'
          },
          exclusions: ['showBikeshare']
        },
        'e-scooter': {
          icon: 'electric_scooter',
          color: 'e-scooter',
          hex: '#198642',
          altColor: 'white',
          permission: 'search.escooter',
          distance: 250
        },
        rental: {
          icon: 'fas fa-car',
          hex: '#EB0004',
          color: 'rental',
          pref: 'rental',
          background: 'backgrounds/carhire.png',
          permission: 'search.rentals',
          routes: {
            search: 'ondemand-car-hire',
            results: 'ondemand-car-hire-results'
          },
          provider: 'mobilleo'
        },
        ferry: {
          icon: 'fas fa-ship',
          color: 'gohi-ferry',
          hex: '#EB0004',
          background: 'backgrounds/ferry.jpg',
          routes: {
            search: 'ondemand-ferry',
            results: 'ondemand-ferry-results'
          }
        },
        flight: {
          icon: 'fas fa-plane',
          hex: '#EB0004',
          pref: 'flight',
          color: 'flight',
          background: 'backgrounds/flight.jpg',
          permission: 'search.flights',
          routes: {
            search: 'ondemand-flights',
            results: 'ondemand-flights-outbound'
          },
          provider: 'mobilleo'
        },
        hotel: {
          icon: 'fas fa-hotel',
          hex: '#EB0004',
          color: 'hotel',
          background: 'backgrounds/hotel.jpg',
          permission: 'search.hotel',
          exclusions: ['desktop'],
          routes: {
            search: 'ondemand-hotel',
            results: 'ondemand-hotel'
          },
          provider: 'mobilleo'
        },
        onwardtravel: {
          text: 'Onward Travel',
          icon: 'fas fa-taxi',
          hex: '#ca62ca',
          color: 'onwardtravel',
          background: 'backgrounds/taxi.jpg',
          permission: 'search.onwardtravel',
          colspan: 12,
          align: 'center',
          exclusions: ['mobilityNearby'],
          routes: {
            search: 'ondemand-onwardtravel',
            results: 'ondemand-onwardtravel-results'
          }
        },
        taxi: {
          icon: 'fas fa-taxi',
          hex: '#EB0004',
          color: 'taxi',
          altColor: 'black',
          background: 'backgrounds/taxi.jpg',
          route: 'ondemand-ride-hailing-results',
          permission: 'search.rides',
          exclusions: ['desktop'],
          routes: {
            search: 'ondemand-ride-hailing',
            results: 'ondemand-ride-hailing-results'
          },
          pref: 'ridehailing',
          provider: 'mobilleo'
        },
        train: {
          text: 'Train',
          icon: 'fas fa-train',
          asset: 'travel-icons/nearby/enabled/train.svg',
          hex: '#4361EE',
          color: 'train',
          background: 'backgrounds/rail.jpg',
          route: 'ondemand-train-depart',
          permission: 'search.trains',
          pref: 'rail',
          routes: {
            search: 'ondemand-trains',
            results: 'ondemand-train-depart'
          },
          provider: 'mobilleo'
        },
        tram: {
          text: 'Trams',
          icon: 'fas fa-subway',
          color: 'tram',
          pref: 'tram',
          hex: '#4361EE',
          background: 'backgrounds/tram.jpg',
          route: 'ondemand-tram-routes',
          permission: 'search.tram',
          routes: {
            search: 'ondemand-tram',
            results: 'ondemand-tram-routes'
          },
          provider: 'mobilleo'
        },
        tube: {
          text: 'Tube',
          icon: 'fas fa-subway',
          color: 'tube',
          hex: '#4361EE',
          permission: 'search.tube',
          provider: 'mobilleo'
        },
        mango: {
          icon: 'asset',
          asset: 'mango-icon.svg',
          color: 'gohi-bus',
          hex: '#fff',
          permission: 'search.bus'
        },
        // Travel aliases
        'Car Hire': { alias: 'rental' },
        car_hire: { alias: 'rental' },
        'car-hire': { alias: 'rental' },
        'escooter': { alias: 'e-scooter' },
        flights: { alias: 'flight' },
        rail: { alias: 'train' },
        trains: { alias: 'train' },
        taxis: { alias: 'taxi' },
        local_taxi: { alias: 'taxi' },
        ridehailing: { alias: 'taxi' },
        'ride-hailing': { alias: 'taxi' },
        Hotels: { alias: 'hotel' },
        hotels: { alias: 'hotel' },
        accommodation: { alias: 'hotel' },
        unibus: { alias: 'mango' },
        citylink: { alias: 'coach' },
        tram_metro: { alias: 'tram' },
        'stagecoach-bus': {
          text: 'Stagecoach Bus',
          alias: 'bus',
          icon: undefined,
          logo: require('assets/stagecoach-icon.png'),
          provider: 'mobilleo',
          exclusions: ['desktop', 'mobilityNearby']
        },
        // Amenities Nearby
        atm: {
          icon: 'fas fa-money-bill-wave',
          hex: '#4361EE',
          color: 'primary',
          mapIcon: 'money-bill-wave'
        },
        bar: {
          icon: 'fas fa-glass-martini-alt',
          hex: '#4361EE',
          color: 'primary',
          mapIcon: 'glass-martini-alt'
        },
        cafe: {
          icon: 'fas fa-coffee',
          hex: '#4361EE',
          color: 'primary',
          mapIcon: 'coffee'
        },
        car_wash: {
          icon: 'fas fa-car-wash',
          hex: '#4361EE',
          color: 'primary',
          mapIcon: 'car-wash'
        },
        carwash: { alias: 'car_wash' },
        car_repair: {
          icon: 'fas fa-car-mechanic',
          hex: '#4361EE',
          color: 'primary',
          mapIcon: 'car-mechanic'
        },
        ev_station: {
          icon: 'fas fa-charging-station',
          hex: '#4361EE',
          color: 'primary',
          mapIcon: 'charging-station'
        },
        gas_station: {
          icon: 'fas fa-gas-pump',
          hex: '#4361EE',
          color: 'primary',
          mapIcon: 'gas-pump'
        },
        parking: {
          icon: 'fas fa-parking',
          hex: '#5D4EA3',
          color: 'primary',
          background: 'backgrounds/parking.jpg'
        },
        restaurant: {
          text: 'restaurants',
          icon: 'fas fa-utensils-alt',
          hex: '#4361EE',
          color: 'primary',
          mapIcon: 'utensils-alt'
        },
        // Derby Amenities
        sociability: {
          icon: 'asset',
          asset: 'sociability.png',
          color: 'pink',
          hex: '#9c27b0',
          pref: 'dialog'
        },
        derbyUniversity: {
          text: 'University of Derby Campus',
          icon: 'asset',
          asset: 'derby-university.png',
          color: 'black',
          hex: '#000000',
          url: 'https://www.derby.ac.uk/life/our-campuses/derby/'
        },
        derbyCollege: {
          text: 'Derby College Campus',
          icon: 'asset',
          asset: 'derby-college.png',
          color: 'black',
          hex: '#000000',
          url: 'https://www.derby-college.ac.uk/our-colleges'
        },
        // Amenities aliases
        fuel: { alias: 'gas_station' },
        restaurants: { alias: 'restaurant' },
        Restaurant: { alias: 'restaurants' },
        // Other
        bicycle: {
          pref: 'bicycle',
          icon: 'fas fa-bicycle',
          color: 'gohi-bike',
          hex: '#198642'
        },
        cycling: { alias: 'bicycle' },
        walking: {
          pref: 'walking',
          icon: 'fas fa-walking',
          color: 'black',
          hex: '#000'
        },
        walk: { alias: 'walking' },
        timeline: {
          icon: 'timeline',
          text: 'timeline'
        },
        manual: {
          icon: 'radio_button_checked',
          text: 'manual'
        },
        booking: {
          icon: 'receipt',
          color: 'purple-6'
        },
        bookings: { alias: 'booking' },
        documents: {
          icon: 'attach_file',
          text: 'documents'
        },
        enterprise: {
          icon: 'asset',
          asset: 'enterprise_e_logo.jpg'
        },
        whatson: {
          text: 'events',
          icon: 'event'
        },
        weather: {
          text: 'weather',
          icon: 'cloud'
        },
        upsell: {
          icon: 'help',
          color: 'blue-8'
        },
        date: {
          icon: 'watch_later',
          color: 'grey-6'
        },
        driving: {
          icon: 'fas fa-car',
          color: 'dark',
          background: 'backgrounds/carhire.png',
          hex: '#EB0004'
        },
        concierge: {
          color: 'teal',
          icon: 'record_voice_over'
        },
        chauffeur: {
          icon: 'airline_seat_recline_normal',
          hex: '#607d8b',
          color: 'blue-grey',
          background: 'backgrounds/taxi.jpg'
        },
        lounge: {
          icon: 'local_bar',
          hex: '#ED507A',
          color: 'pink-5',
          background: 'backgrounds/flight.jpg'
        },
        'Airport Lounge': { alias: 'lounge' },
        eurostar: {
          icon: 'train',
          hex: '#1a237e',
          color: 'indigo-10',
          background: 'backgrounds/rail.jpg'
        },
        other: {
          icon: 'layers',
          color: 'green'
        },
        start_end_marker: {
          icon: 'home',
          color: 'green'
        },
        theatre: {
          text: 'Theatre'
        },
        locallink: {
          text: 'Local Link'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      travelPreferences: 'preferences',
      mixinOrganisation: 'organisation'
    })
  },
  methods: {
    getContentFromAlias (type) {
      const name = this.travelContents[type]?.alias || type
      return {
        ...this.travelContents[name],
        ...this.travelContents[type]
      }
    },
    getTravelContentBackground (type) {
      const content = this.getContentFromAlias(type)
      return content?.background
    },
    getTravelContentName (type) {
      const content = this.getContentFromAlias(type)
      return content?.text
    },
    getTravelContentIcon (type) {
      const content = this.getContentFromAlias(type)
      return content?.icon || content?.logo
    },
    getTravelContentLogo (type) {
      const content = this.getContentFromAlias(type)
      return content?.logo || content?.icon
    },
    getMapIcon (type) {
      // The name of the svg file in assets/markers
      const content = this.getContentFromAlias(type)
      if (content.mapIcon) return content.mapIcon
      const pref = this.getTravelPreferenceName(type)
      return pref
    },
    getTravelContentColor (type) {
      const content = this.getContentFromAlias(type)
      return content?.color
    },
    getTravelContentAltColor (type) {
      const content = this.getContentFromAlias(type)
      return content?.altColor || 'white'
    },
    getTravelContentHex (type) {
      const content = this.getContentFromAlias(type)
      return content?.hex
    },
    getTravelContentProvider (type) {
      const content = this.getContentFromAlias(type)
      return content?.provider
    },
    getAssetPath (type) {
      const content = this.getContentFromAlias(type)
      if (!content?.asset) return 'missing-32.png'
      return content?.asset
    },
    getTravelContentRadius (type) {
      const content = this.getContentFromAlias(type)
      return content?.distance
    },
    getTravelContentRoute (type, route) {
      const content = this.getContentFromAlias(type)
      return content?.routes?.[route]
    },
    getUrl (type) {
      const content = this.getContentFromAlias(type)
      return content?.url
    },
    travelContentExcluded (type, exclusions) {
      const content = this.getContentFromAlias(type)
      if (!content.exclusions) return false
      return exclusions.some(exclusion => content.exclusions.includes(exclusion))
    },
    /**
     * Previously typeToPermissionMapper
     * Returns the permission name of the content type
     */
    getTravelPermission (type) {
      const content = this.getContentFromAlias(type)
      return content.permission || `search.${type}`
    },
    /**
     * Previously typeToContentMapper
     * Returns the name used by user.travel_preference
    */
    getTravelPreferenceName (type) {
      const content = this.getContentFromAlias(type)
      return content?.pref || type
    },
    hasTypePermission (type) {
      const permission = this.getTravelPermission(type)
      if (permission) return hasPermission(permission)
      return false
    },
    isTypeEnabled (type) {
      if (['ferry', 'mango', 'drt', 'coach'].includes(type)) return true
      const pref = this.getTravelPreferenceName(type)
      const permission = this.getTravelPermission(type)
      return hasPermission(permission) && !this.travelPreferences[pref]?.hidden
    },
    isPolicyEnabled (type) {
      const pref = this.getTravelPreferenceName(type)
      const policy = this.mixinOrganisation?.attributes?.travel_policy
      return policy
        ? policy[pref]
          // If there is a policy entry for pref, return the enabled value
          ? policy[pref].enabled
          // Otherwise treat it as disabled
          : false
        // Unless there is no policy at all, in which case treat everything as enabled
        : true
    }
  }
}
